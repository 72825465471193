<app-page-wrapper
	[TITLE]="'ORDERS.TITLE'"
	[DESCRIPTION]="'ORDERS.DESCRIPTION'"
	(search)="searchValueChange($event)"
>
	<ng-template #messages>
		@if (settingsMessage) {
			<app-display-message
				[message]="settingsMessage"
				[firstLinebreak]="true"
				(close)="closeSettingsMsg()"
				(action)="goToSettings()"
			/>
		}
		@if (browserMessage) {
			<app-display-message
				[message]="browserMessage"
				[firstLinebreak]="true"
				(close)="closeBrowserMsg()"
			/>
		}
		@if (displayMessage) {
			<app-display-message
				[message]="displayMessage"
				[firstLinebreak]="true"
				(close)="closeAdapterMsg()"
			/>
		}
		@if (trainingMsg) {
			<app-display-message
				[message]="trainingMsg"
				[firstLinebreak]="true"
				(action)="openTraining()"
			/>
		}
		@if (trainingDoneMsg) {
			<app-display-message
				[message]="trainingDoneMsg"
				[firstLinebreak]="true"
				(action)="finishTraining()"
				(descAction)="openTraining()"
			/>
		}
		@if (sensitiveDataMsg) {
			<app-display-message
				[message]="sensitiveDataMsg"
				[firstLinebreak]="true"
				(close)="closeSensitiveDataMsg()"
				(action)="dismissSensitiveDataMsg()"
			/>
		}
	</ng-template>
	<ng-template #content>
		<div class="flex flex-col h-full gap-y-4">
			<!-- <div class="flex-initial"></div> -->
			<div class="flex-initial">
				<app-orders-filter />
				<hr class="mt-4 text-g-1-400" />
			</div>
			<div
				name="orderOverview"
				class="flex-auto overflow-x-hidden overflow-y-auto"
				infinite-scroll
				[scrollWindow]="false"
				(scrolled)="onScroll()"
			>
				@if (canCreateOrders && trainingCheck) {
					<app-order-creation />
					<br />
				}
				<app-order-overview />
			</div>
		</div>
	</ng-template>
</app-page-wrapper>
