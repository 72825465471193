import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
	FakeMissingTranslationHandler,
	TranslateDefaultParser,
	TranslateFakeCompiler,
	TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Subscription, combineLatest, skipWhile } from 'rxjs';
import {
	DEFAULT_DEMO_LANG,
	DEFAULT_LANG,
	DEMO_PREFIX,
	PREFIX,
} from './language.service';
import { PharmacySettingsService } from './pharmacy-settings.service';
import { StateService } from './state.service';

@Injectable()
export class CommunicationLanguageTranslationService
	extends TranslateService
	implements OnDestroy
{
	private subs: Subscription = new Subscription();

	constructor(
		http: HttpClient,
		private pharmacySettingsService: PharmacySettingsService,
		private state: StateService,
	) {
		super(
			null,
			new TranslateHttpLoader(
				http,
				'https://r0wapclouddevstore.blob.core.windows.net/i18n/',
				// 'assets/i18n/',
				`.json?v=${new Date().getTime()}`,
			),
			new TranslateFakeCompiler(),
			new TranslateDefaultParser(),
			new FakeMissingTranslationHandler(),
			false,
			true,
			false,
			null,
		);

		this.subs.add(
			combineLatest([
				this.pharmacySettingsService.onPharmacySettingsChanged,
				this.state.state$,
			])
				.pipe(skipWhile((x) => x[0] == null || x[1] == null))
				.subscribe((x) => {
					this.change(x[0].communicationLanguage);
				}),
		);
	}

	ngOnDestroy(): void {
		if (this.subs) this.subs.unsubscribe();
	}

	usedefault() {
		this.use(this.state.isDemo ? DEFAULT_DEMO_LANG : DEFAULT_LANG);
	}

	async change(lang: string) {
		if (lang.indexOf('-') === -1) {
			this.usedefault();
			return;
		}

		lang = `${PREFIX}${this.state.isDemo ? DEMO_PREFIX : ''}.${lang.split('-')[0]}`;

		if (!this.getLangs().some((x) => x === lang)) {
			this.usedefault();
		}

		this.use(lang);
	}
}
