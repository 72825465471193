import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import {
	Observable,
	Subscription,
	combineLatest,
	lastValueFrom,
	map,
	skipWhile,
} from 'rxjs';
import { ToastType } from 'src/app/core/enums/toast-type.enum';
import { IDisplayMessage } from 'src/app/core/interfaces/display-message.interface';
import { BrowserDetectionService } from 'src/app/core/services/browser-detection.service';
import { PharmacySettingsService } from 'src/app/core/services/pharmacy-settings.service';
import { StateService } from 'src/app/core/services/state.service';
import { SwaggerService } from 'src/app/core/services/swagger.service';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/swagger';
import { environment } from 'src/environments/environment';
import { MODE } from 'src/environments/environment.helper';
import { DisplayMessageComponent } from '../../core/components/display-message/display-message.component';
import { PageWrapperComponent } from '../../core/components/page-wrapper/page-wrapper.component';
import { PageWrapperService } from '../../core/components/page-wrapper/page-wrapper.service';
import { OrderCreationComponent } from './order-creation/order-creation.component';
import { OrderOverviewComponent } from './order-overview/order-overview.component';
import { OrderOverviewService } from './order-overview/order-overview.service';
import { OrderSettingsService } from './orderSettings.service';
import { OrdersFilterComponent } from './orders-filter/orders-filter.component';

type hotline = {
	country: string;
	locale: string;
	number: string;
	mail: string;
};

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	standalone: true,
	imports: [
		PageWrapperComponent,
		DisplayMessageComponent,
		OrdersFilterComponent,
		InfiniteScrollDirective,
		OrderCreationComponent,
		OrderOverviewComponent,
	],
})
export class OrdersComponent implements OnDestroy {
	subs: Subscription = new Subscription();
	displayMessage: IDisplayMessage;
	browserMessage: IDisplayMessage;
	settingsMessage: IDisplayMessage;
	trainingMsg: IDisplayMessage;
	trainingDoneMsg: IDisplayMessage;
	sensitiveDataMsg: IDisplayMessage;
	distance: number = 0.2;
	throttle: number = 0;
	callOngoing: boolean = false;
	allOrdersLoaded: boolean = false;
	canCreateOrders: boolean = false;
	trainingCheck: boolean = false;
	training$: Observable<hotline>;
	httpClient: HttpClient;

	constructor(
		private orderOverviewService: OrderOverviewService,
		private orderInputSettings: OrderSettingsService,
		private browserDetection: BrowserDetectionService,
		private pharmacySettingsService: PharmacySettingsService,
		private router: Router,
		private userService: UserService,
		private pagewrapper: PageWrapperService,
		private state: StateService,
		private httpB: HttpBackend,
		private translate: TranslateService,
		private swagger: SwaggerService,
	) {
		this.httpClient = new HttpClient(this.httpB);
		if (this.orderOverviewService.searchValues.length > 0) {
			this.pagewrapper.searchValue$.next(
				this.orderOverviewService.searchValues.join(' '),
			);
		}

		this.subs.add(
			this.orderOverviewService.showLoader$.subscribe(
				(state: boolean) => (this.callOngoing = state),
			),
		);

		this.subs.add(
			this.orderOverviewService.allOrderLoaded$.subscribe(
				(state: boolean) => (this.allOrdersLoaded = state),
			),
		);

		this.subs.add(
			this.browserDetection.usableBrowser.subscribe((isUsable) => {
				if (!isUsable && !this.browserDetection.closed) {
					this.browserMessage = <IDisplayMessage>{
						title: 'BROWSER.TITLE',
						description: 'BROWSER.DESCRIPTION',
						showIcon: true,
						type: ToastType.WARNING,
						closeable: true,
					};
				}
			}),
		);

		this.subs.add(
			this.pharmacySettingsService.pharmacySettingsNotCompleted.subscribe(
				(pharmacySettingsNotCompleted) => {
					if (pharmacySettingsNotCompleted) {
						this.settingsMessage = <IDisplayMessage>{
							title: 'SETTINGS_MISSING.TITLE',
							description: 'SETTINGS_MISSING.DESCRIPTION',
							action: 'SETTINGS_MISSING.LINK',
							showIcon: true,
							type: ToastType.WARNING,
							closeable: false,
						};
					} else {
						this.closeSettingsMsg();
					}
				},
			),
		);

		if (
			environment.MODE !== MODE.DEMO &&
			environment.MODE !== MODE.DEMOPROD &&
			!this.pharmacySettingsService.onPharmacySettingsChanged.value
				?.trainingDone
		) {
			this.setupDemo();
		} else {
			this.trainingCheck = true;
		}

		this.subs.add(
			this.userService.isSuperAdmin$
				.pipe(skipWhile((_) => !this.trainingCheck))
				.subscribe((val) => (this.canCreateOrders = !val)),
		);

		this.orderInputSettings.loadSettings();

		if (
			!this.orderOverviewService.sensitiveDataClosed &&
			!this.userService.user$.value.dataNoticeMsgClosed
		) {
			this.sensitiveDataMsg = {
				title: 'SENSITIVE_DATA_DISCLOSURE.TITLE',
				description: 'SENSITIVE_DATA_DISCLOSURE.DESCRIPTION',
				action: 'DISMISS_PERMANENTLY',
				showIcon: true,
				type: ToastType.WARNING,
				closeable: true,
			} as IDisplayMessage;
		}
	}

	setupDemo() {
		this.subs.add(
			combineLatest([
				this.pharmacySettingsService.onPharmacySettingsChanged,
				this.orderOverviewService.orderCount$,
				this.userService.isSuperAdmin$,
				this.httpClient.get<hotline[]>(
					`https://r0wapclouddevstore.blob.core.windows.net/ui-assets/hotline.json`,
					{
						headers: new HttpHeaders().set(
							'Content-Type',
							'application/json',
						),
					},
				),
			])
				.pipe(
					skipWhile(
						(x) =>
							x[0] == null ||
							x[1] == null ||
							x[2] == null ||
							x[3] == null,
					),
					map((x) => {
						return {
							settings: x[0],
							orderDoneCount: x[1].pickedUp + x[1].canceled,
							superadmin: x[2],
							hotlines: x[3],
						};
					}),
				)
				.subscribe((dto) => {
					if (!dto.settings.trainingDone && dto.orderDoneCount < 10) {
						this.state.set_state_training();
						this.canCreateOrders = false;

						const pharmalocale =
							(dto.settings.country.split(' ')[1] ?? '').slice(
								1,
								3,
							) ?? undefined;

						const hotline = pharmalocale
							? [...dto.hotlines].find(
									(x) =>
										x.locale.toLowerCase() ===
										pharmalocale.toLowerCase(),
								)
							: undefined;

						this.trainingMsg = {
							title: 'TRAINING.ONGOING.TITLE',
							description: this.gethotlinetext(hotline),
							action: 'TRAINING.ONGOING.ACTION',
							showIcon: true,
							type: ToastType.WARNING,
							closeable: false,
						} as IDisplayMessage;
					} else {
						this.state.set_state_normal();
						this.canCreateOrders = !dto.superadmin;
						this.trainingMsg = undefined;

						if (!dto.settings.trainingDone) {
							this.trainingDoneMsg = {
								title: 'TRAINING.DONE.TITLE',
								description: `TRAINING.DONE.DESCRIPTION`,
								action: 'TRAINING.DONE.FINISH',
								descriptionAction: 'TRAINING.DONE.ACTION',
								showIcon: true,
								type: ToastType.INFO,
								closeable: false,
							} as IDisplayMessage;
						} else {
							this.trainingDoneMsg = undefined;
						}
					}
					this.trainingCheck = true;
				}),
		);
	}

	gethotlinetext(hotline: hotline): string {
		if (!hotline) {
			return 'TRAINING.ONGOING.DESCRIPTION';
		}

		if (hotline.number && !hotline.mail) {
			return this.translate.instant(
				'TRAINING.ONGOING.DESCRIPTION_NUMBER',
				{
					number: hotline.number,
				},
			);
		}

		if (!hotline.number && hotline.mail) {
			return this.translate.instant('TRAINING.ONGOING.DESCRIPTION_MAIL', {
				mail: hotline.mail,
			});
		}

		return this.translate.instant(
			'TRAINING.ONGOING.DESCRIPTION_NUMBER_AND_MAIL',
			{
				number: hotline.number,
				mail: hotline.mail,
			},
		);
	}

	onScroll() {
		if (!this.callOngoing && !this.allOrdersLoaded) {
			this.orderOverviewService.loadOrders();
		}
	}

	closeBrowserMsg() {
		this.browserMessage = undefined;
		this.browserDetection.closed = true;
	}

	closeAdapterMsg() {
		this.displayMessage = undefined;
	}
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	searchValueChange(value: string) {
		this.orderOverviewService.applySearchValue(
			value === '' || value == null ? [] : value.split(' ') ?? [],
		);
	}

	closeSettingsMsg() {
		this.settingsMessage = undefined;
	}

	closeSensitiveDataMsg() {
		this.orderOverviewService.sensitiveDataClosed = true;
		this.sensitiveDataMsg = undefined;
	}

	async dismissSensitiveDataMsg() {
		this.closeSensitiveDataMsg();
		await lastValueFrom(this.swagger.user.apiUserCloseDataNoticePost());
		this.userService.user$.next({
			...this.userService.user$.value,
			dataNoticeMsgClosed: true,
		} as User);
	}

	finishTraining() {
		this.pharmacySettingsService.finishTraining();
	}

	openTraining() {
		window.open('https://clc.rowa.de/', '_blank', 'noopener');
	}

	goToSettings() {
		this.router.navigate(['pharmacysettings']);
	}
}
