import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { MODE } from './environments/environment.helper';

import { appCfg } from './app/app.config';

if (environment.MODE === MODE.PROD || environment.MODE === MODE.DEMOPROD) {
	enableProdMode();
}

bootstrapApplication(AppComponent, appCfg).catch((err) => console.error(err));
