import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RowaAnimation } from '@rowa/core';
import { Observable, Subject, Subscription, lastValueFrom, of } from 'rxjs';
import { map, skipWhile, startWith, takeUntil } from 'rxjs/operators';
import { InactivityService } from 'src/app/core/components/inactivity/inactivity.service';
import { AppTooltipDirective } from 'src/app/core/directives/app-tooltip.directive';
import { PharmacySettingsService } from 'src/app/core/services/pharmacy-settings.service';
import { SignalRService } from 'src/app/core/services/signal-r.service';
import {
	DemoDisplayDirective,
	NormalDisplayDirective,
	StateService,
} from 'src/app/core/services/state.service';
import { UserService } from 'src/app/core/services/user.service';
import { OrganisationMode, PharmacySettingsDto, User } from 'src/app/swagger';
import { RowaCoreModule } from '../../../../libs/core/src/lib/rowa-core.module';
import { RowaIconModule } from '../../../../libs/ui/src/lib/icon/icon.module';
import { RowaProfileMenuModule } from '../../../../libs/ui/src/lib/profile-menu/profile-menu.module';
import { RowaShellModule } from '../../../../libs/ui/src/lib/shell/shell.module';
import { IconsComponent } from '../../core/components/icons/icons.component';
import { SwaggerService } from '../../core/services/swagger.service';
import { AuthService } from '../auth/auth.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	animations: [RowaAnimation.FadeDownUp],
	standalone: true,
	imports: [
		RowaShellModule,
		RowaProfileMenuModule,
		RowaCoreModule,
		RouterLink,
		RowaIconModule,
		TranslateModule,
		AsyncPipe,
		DemoDisplayDirective,
		NormalDisplayDirective,
		DatePipe,
		IconsComponent,
		AppTooltipDirective,
	],
})
export class NavbarComponent implements OnInit, OnDestroy {
	pharmacyName: string = '';
	settings: PharmacySettingsDto;
	subs: Subscription = new Subscription();
	private ngUnsubscribe = new Subject();
	userProfile = {
		name: '',
		role: '',
	};
	isDemo: boolean;
	canRedirectToNormal$: Observable<boolean>;
	isInternalUser: boolean;
	inactivityTime$: Observable<string>;
	inactivityEnabled$: Observable<boolean>;

	constructor(
		private swagger: SwaggerService,
		private signalR: SignalRService,
		private route: Router,
		private translate: TranslateService,
		private authService: AuthService,
		private msalService: MsalService,
		private userService: UserService,
		private state: StateService,
		private inactivitySvc: InactivityService,
		private pharamcySettingsSvc: PharmacySettingsService,
	) {
		this.subs.add(
			this.signalR.pharmacyNameChange.subscribe(
				(pharamacyName) => (this.pharmacyName = pharamacyName),
			),
		);

		this.subs.add(
			this.translate.onLangChange.subscribe(() => {
				this.userProfile = {
					name: !this.userService.user$.value
						? this.translate.instant(
								'PROFILE.DETAILS.FULL_NAME.NOT_SET',
							)
						: this.userProfile.name,
					role: this.userService.isSuperAdmin$.value
						? this.translate.instant('PROFILE.USERROLE.SUPERADMIN')
						: this.translate.instant('PROFILE.USERROLE.ADMIN'),
				};
			}),
		);

		this.subs.add(
			this.userService.isSuperAdmin$.subscribe(
				(val) =>
					(this.userProfile.role = val
						? this.translate.instant('PROFILE.USERROLE.SUPERADMIN')
						: this.translate.instant('PROFILE.USERROLE.ADMIN')),
			),
		);

		this.subs.add(
			this.userService.isInternalUser$.subscribe(
				(isInternalUser) => (this.isInternalUser = isInternalUser),
			),
		);

		this.subs.add(
			this.userService.user$.subscribe((user: User) => {
				if (user == null) {
					this.translate
						.get('PROFILE.DETAILS.FULL_NAME.NOT_SET')
						.pipe(takeUntil(this.ngUnsubscribe))
						.subscribe((value) => (this.userProfile.name = value));
				} else {
					this.ngUnsubscribe.next(undefined);
					this.ngUnsubscribe.complete();
					this.userProfile.name = user.fullName;
				}
			}),
		);

		this.isDemo = this.state.isDemo;

		this.subs.add(
			this.authService.isAuth$.subscribe((_) => {
				this.canRedirectToNormal$ = of(
					this.authService.pharmacyList,
				).pipe(
					map((x) =>
						x.find(
							(x) => x.id === this.authService.currentPharmacyId,
						),
					),
					map(
						(x) =>
							x.organisationMode ===
							OrganisationMode.DemoAndStandard,
					),
				);
			}),
		);

		this.inactivityEnabled$ =
			this.pharamcySettingsSvc.onPharmacySettingsChanged.pipe(
				skipWhile((x) => x == null),
				map((x) => x.enableInactivity),
				startWith(false),
			);

		this.inactivityTime$ = this.inactivitySvc.inactivityTimer;
	}

	async ngOnInit() {
		//TODO
		//needed for e2e test, find a better way to load in all needed data, somehow the init method from the login component doesnt get called
		//probably will create a coonfiguration for the e2e tests for this
		if ((window as any).Cypress?.env().TEST_AUTH) {
			await this.authService.loadFromStorage();
			this.authService.isAuth$.next(this.authService.isAuthenticated());
		}
		this.loadSettings();
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	async loadSettings() {
		this.pharmacyName = await lastValueFrom(
			this.swagger.PharmacySettingsServiceAPI.apiPharmacysettingsGetPharmacyNameGet(),
		);
	}

	openPartnersPage() {
		this.route.navigate(['partners']);
	}

	gotoTrainingRowa() {
		if (this.isInternalUser) {
			window.open('https://training.rowa.de/', '_blank', 'noopener');
		} else {
			window.open('https://clc.rowa.de/', '_blank', 'noopener');
		}
	}

	switchToDemo() {
		if (isDevMode()) {
			alert('ng serve -c demo || npm run demo');
		} else {
			if (window.location.href.indexOf('staging') > -1) {
				window.open(
					window.location.href.replaceAll('staging', 'demo-staging'),
					'_self',
				);
			} else {
				window.open(
					window.location.href.replaceAll('pickup', 'demo.pickup'),
					'_self',
				);
			}
		}
	}

	switchToNormal() {
		if (isDevMode()) {
			alert('ng serve || npm run start');
		} else {
			if (window.location.href.indexOf('demo-staging') > -1) {
				window.open(
					window.location.href.replaceAll('demo-staging', 'staging'),
					'_self',
				);
			} else {
				window.open(
					window.location.href.replaceAll('demo.pickup', 'pickup'),
					'_self',
				);
			}
		}
	}

	openUserProfile() {
		this.route.navigate(['profile']);
	}

	doLogout() {
		this.authService.logout();
		this.msalService.logoutRedirect();

		setTimeout(() => {
			this.route.navigate(['']);
		}, 500);
	}
}
