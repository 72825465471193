import { DatePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PharmacySettingsService } from 'src/app/core/services/pharmacy-settings.service';
import { PrintService } from 'src/app/core/services/print.service';
import { UserService } from 'src/app/core/services/user.service';
import { GetOrderResponse } from 'src/app/swagger';
import { CensorNamePipe } from '../../../core/pipes/censor.pipe';
import { TranslateCommunicationLanguagePipe } from '../../../core/pipes/translate-communication-language.pipe';
import { PharmacySettingsDto } from './../../../swagger/model/pharmacySettingsDto';

@Component({
	selector: 'app-pickup-print',
	templateUrl: './pickup-print.component.html',
	styles: [
		`
			@media screen {
				:host {
					display: none;
				}
			}
		`,
	],
	standalone: true,
	imports: [
		DatePipe,
		TranslateModule,
		TranslateCommunicationLanguagePipe,
		CensorNamePipe,
	],
})
export class PickupPrintComponent implements OnDestroy {
	isSuperAdmin: boolean;
	QRCodeImg: string;
	order: GetOrderResponse;
	settings: PharmacySettingsDto;

	private subs: Subscription = new Subscription();

	constructor(
		private printService: PrintService,
		private pharmacySettingsService: PharmacySettingsService,
		private userService: UserService,
	) {
		this.subs.add(
			this.pharmacySettingsService.onPharmacySettingsChanged.subscribe(
				(x) => (this.settings = x),
			),
		);

		this.subs.add(
			this.printService.curPickupOrder.subscribe(async (x) => {
				if (x != null) {
					this.order = x;
					this.QRCodeImg = await this.printService.createQRCode(
						this.order.pickupCode,
					);
					this.printService.isPrintingPickup$.next(true);
					this.printService.onDataReady(
						this.printService.isPrintingPickup$,
					);
				}
			}),
		);

		this.subs.add(
			this.userService.isSuperAdmin$.subscribe(
				(x) => (this.isSuperAdmin = x),
			),
		);
	}

	ngOnDestroy(): void {
		if (this.subs) this.subs.unsubscribe();
	}
}
