import { Injectable, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationLanguageTranslationService } from './custom-translation.service';
import { LocalStorageService } from './local-storage.service';
import * as Locales from 'date-fns/locale';
import { Locale } from 'date-fns';
import { languagePool } from 'src/assets/languagePool';
import { StateService } from './state.service';

export const PREFIX = 'pickupcloud';
export const DEMO_PREFIX = '.demo';
export const DEFAULT_LANG: string = `${PREFIX}.en`;
export const DEFAULT_DEMO_LANG: string = `${PREFIX}${DEMO_PREFIX}.en`;
const DEFAULT_DEV_LANG: string = `${PREFIX}`;
const DEFAULT_DEV_DEMO_LANG: string = `${PREFIX}${DEMO_PREFIX}`;
const LOCAL_LANGUAGE_KEY = 'LOCAL_LANGUAGE';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	currentLang: string;
	dateFNSLocale: { [key: string]: Locale } = {};
	langPool = languagePool.map((x) => `${PREFIX}${x.value}`);
	demoLangPool = languagePool.map((x) => `${PREFIX}${DEMO_PREFIX}${x.value}`);

	constructor(
		private translate: TranslateService,
		private communicationTranslate: CommunicationLanguageTranslationService,
		private localStorage: LocalStorageService,
		private state: StateService,
	) {}

	async init() {
		await this.state.init();
		this.currentLang = this.getCurLangSetInStorage();
		if (this.state.isDemo) {
			if (this.currentLang && this.currentLang.indexOf('demo') === -1) {
				const curlangsplitted = this.currentLang.split('.');
				if (curlangsplitted.length === 1) {
					this.currentLang = curlangsplitted + '.demo';
				} else {
					this.currentLang = curlangsplitted.join('.demo.');
				}
			}
			this.init_demo();
		} else {
			if (this.currentLang && this.currentLang.indexOf('demo') > -1) {
				this.currentLang = this.currentLang.split('.demo').join('');
			}
			this.init_normal();
		}
	}

	async init_demo() {
		if (!isDevMode()) {
			this.translate.addLangs([...this.demoLangPool]);
			this.communicationTranslate.addLangs([...this.demoLangPool]);
			this.changeLanguage(this.currentLang ?? DEFAULT_DEMO_LANG);
		} else {
			this.translate.addLangs([
				DEFAULT_DEV_DEMO_LANG,
				...this.demoLangPool,
			]);
			this.communicationTranslate.addLangs([
				DEFAULT_DEV_DEMO_LANG,
				...this.demoLangPool,
			]);
			this.changeLanguage(this.currentLang ?? DEFAULT_DEV_DEMO_LANG);
		}
	}

	async init_normal() {
		if (!isDevMode()) {
			this.translate.addLangs([...this.langPool]);
			this.communicationTranslate.addLangs([...this.langPool]);
			this.changeLanguage(this.currentLang ?? DEFAULT_LANG);
		} else {
			this.translate.addLangs([DEFAULT_DEV_LANG, ...this.langPool]);
			this.communicationTranslate.addLangs([
				DEFAULT_DEV_LANG,
				...this.langPool,
			]);
			this.changeLanguage(this.currentLang ?? DEFAULT_DEV_LANG);
		}
	}

	changeToDemoLanguage() {
		this.currentLang = this.getCurLangSetInStorage();
		this.changeLanguage(
			`${PREFIX}${DEMO_PREFIX}${this.currentLang.replace(PREFIX, '')}`,
		);
	}

	changeToNormalLanguage() {
		this.currentLang = this.getCurLangSetInStorage();
		this.changeLanguage(
			`${PREFIX}${this.currentLang.replace(
				`${PREFIX}${DEMO_PREFIX}`,
				'',
			)}`,
		);
	}

	getDateFNSLocale(locale: string): Locale {
		return Locales[locale] ?? Locales.enUS;
	}

	public async changeLanguage(newLanguage: string): Promise<void> {
		if (this.translate.getLangs().some((x) => x === newLanguage)) {
			this.translate.use(newLanguage);
			this.currentLang = newLanguage;
			this.localStorage.setItem(LOCAL_LANGUAGE_KEY, newLanguage);
		} else {
			console.error(`language (${newLanguage}) not supported!`);
		}
	}

	public getPrefix = (): string => PREFIX;
	public getDemoPrefix = (): string => DEMO_PREFIX;
	public getLangs = (): Array<string> => this.translate.getLangs();
	public curLang = (): string => this.translate.currentLang;
	public getCurLangSetInStorage = (): string =>
		this.localStorage.getData(LOCAL_LANGUAGE_KEY);
}
